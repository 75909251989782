<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="description" placeholder="Description"/>
        </CCol>
        <CCol lg="3">
          <CInput v-model="model" placeholder="Model"/>
        </CCol>
        <CCol lg="3">
           <v-select 
            label="label" 
            :options="[
                {
                label: 'Gondola',
                value: 'gondola'
              },
              {
                label: 'Light/Powertools',
                value: 'light/powertools'
              },
               {
                label: 'Convan',
                value: 'convan'
              },
              {
                label: 'Lifting',
                value: 'lifting'
              },
              {
								label: 'heavy/machineries',
								value: 'Heavy/Machineries'
											}
            ]"
            :reduce="item => item.value"
            v-model="classification"
            placeholder="Classification"
          > </v-select>
        </CCol> 
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="currentList.data"
            :reduce="item => item.id"
            v-model="current_location_id"
            placeholder="Current Location"
          > 
          </v-select> 
        </CCol> 
        <!-- <CCol lg="2">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol> -->
      </CRow>
      <CRow>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>  
        </CCol>
         <CCol lg="3">
           <v-select 
            label="label" 
            :options="[
              {
                label: 'All',
                value: 'all'
              },
                  { label: 'Operational',
                    value: 'Operational'
                      
               }, 
               
                  { label: 'Breakdown',
                    value: 'breakdown'
                      
               }, 
              
                  { label: 'For Scrap',
                    value: 'for scrap'
                      
               },
                { label: 'Standby',
                    value: 'standby'
                      
               }, 
               { label: 'For Erection',
                    value: 'for erection'
                      
               },
                { label: 'For Inspection',
                    value: 'for inspection'
                      
               }, 
               { label: 'Reserve',
                    value: 'for reserve'
                      
               },
                { label: 'Dismantle',
                    value: 'dismantle'
                      
               },
               { label: 'For Rehab',
                    value: 'for rehab'
                      
               },
                { label: 'Purchase',
                    value: 'purchase'
                      
               }
                      
              
            ]"
            :reduce="item => item.value"
            v-model="status"
            placeholder="Status"
          > </v-select> 
        </CCol>
       <CCol lg="3">
          <CInput v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        <CCol lg="3">
          <CInput  v-model="type" placeholder="Type"/>
        </CCol>
        <CCol lg="9">
        
        </CCol>
        <CCol lg="3" class="mt-1  text-right">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
       
        <!-- <CCol lg="3">
          
        </CCol> -->
        <!-- <CCol lg="2">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol> -->
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            classification: "",
            origin_id: "",
            current_location_id:"",
            description:"",
            model:"",
            status:"all",
            body_no: "",
            vehicle_type_id: "",
            maker_model_id: "",
            year: "", 
            asset_no: "",
            type:"",
            currentList: {
              data: []
            },
            originList: {
              data: []
            },
      makerModelList: {
        data: []
      }
          }
        },
      mounted() {
        // this.getOrigin();
        // this.getCurrentLocation();
        // this.getMakerModel();
      },
      props: {
        show: false
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
           classification: this.classification,
           current_location_id: this.current_location_id,
            origin_id: this.origin_id,
            description: this.description,
            model: this.model,
             status: this.status,
            asset_no: this.asset_no,
            type: this.type
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.classification = "";
          this.origin_id = "";
          this.current_location_id ="",
          this.description="";
          this.model="",
          this.status="all",
          this.asset_no="",
          this.type = "";
   
          const data = {
            classification: this.classification,
            origin_id: this.origin_id,
             current_location_id: this.current_location_id,
             description: this.description,
              model: this.model,
              status: this.status,
            asset_no: this.asset_no,
            type: this.type,
           
          }
          this.$emit("depot-search-query", data)
        },

      //   getCustomer(){
	    // 	axios.get(config.api_path+'/reference/customer-list', {
      //       params: {
      //         show_all : true
      //       }
      //     })
	    // 	.then(response => {
	    // 		this.customerList = response.data; 
	    // 	})

	    // }, 


        getCurrentLocation(){
        axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.currentList = response.data; 
	    	})
      },
       getOrigin(){

        axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.originList = response.data; 
          this.currentList = response.data; 
	    	})
      },

      
      },
      watch: {
        show(val) {
          if(val) {
            if(!this.originList?.data?.length)
              this.getOrigin()
          }
        }
      }
    }
</script>

<style scoped>

</style>
