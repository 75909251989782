<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Equipments Report ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
		      		 <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<!-- <CButton 
							 v-if="config.getPermission('equipment_reports').download"
							 size="sm" style="margin-right: 10px" 
							 color="primary" 
							 @click="download"
							 class="float-lg-right">
							 <i class="fa fa-download">
							 </i>Download
					 </CButton> -->
					<CButton 
							v-if="config.getPermission('equipment_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							class="float-lg-right"
							@click="() => { showModalDownloadFilter = true }">
							<i class="fa fa-download"></i> Download
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search" :show="showCollapse"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      	
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
				    :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>

			    	
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow> 
		  	<!-- <HistoryModal :details="selectedRow" @showModal="historyModal = $event; reloadData();" :showModal="historyModal" />
		   	<TransferModal :details="selectedRow" @showModal="transferModal = $event; reloadData();" :showModal="transferModal" /> -->
		
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './equipment_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import downloadFilter from './equipment_report_filter.vue';
import '../style.css';


export default {
	mounted(){  
		// this.getVendor();
		// this.getDepot();
		this.getData();  
	},
	data(){
		return{
			showModalDownloadFilter:false,
			config,
			isLoading: false,
			// historyModal: false,
			// transferModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  




			depotName: "", 
			fields: [
				{
					key: 'classification', 
					label: 'Classification'
				},
				{
					key: 'asset_no', 
					label: 'Asset No.'
				},
				{
					key: 'equipment_prep_no', 
					label: 'Equipment Prep No.'
				},
				
				{
					key: 'description', 
					label: 'Description'
				}, 
				{
					key: 'model', 
					label: 'Model'
				},
				{
					key: 'type', 
					label: 'Type'
				},
				// {
				// 	key: 'acquisition_date', 
				// 	label: 'Acquisition Date'
				// },
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				// {
				// 	key: 'acquisition_date', 
				// 	label: 'Acquisition Date'
				// },
				{
					key: 'current_location_id_label', 
					label: 'Current Location',
				}, 
				{
					key: 'depot_name', 
					label: 'Depot'
				},
				
				// {
				// 	key: 'transfer_date', 
				// 	label: 'Transfer Date'
				// },
				{
					key: 'status', 
					label: 'Status'
				},
				// {
				// 	key: 'action', 
				// 	label: 'Action'
				// }
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				classification: "",
				origin_id: "",
				current_location_id:"",
				description:"",
				model:"",
				status:"",
				asset_no: "",
				type:"",
			},
			// vendorList: {
			// 	data: []
			// },
			// depotList: {
			// 	data:[]
			// }
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect,downloadFilter},
	methods: {
		 getBadge (status) {
    	return status === 'operational' ? 'success'
       		 : status === 'breakdown' ? 'dark'
         	 : status === 'for scrap' ? 'warning'
			 : status === 'standby' ? 'secondary'
			 : status === 'for erection' ? 'primary'
         	 : status === 'for inspection' ? 'info'
			 : status === 'dismantle' ? 'light'
			 :status === 'for rehab' ? 'success'
			 :status === 'pull-out' ? 'success'
             : status === 'reserve' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

		// updatePageUnderReview(data){
		// 	this.currentPageUnderReview = data;
		// 	this.getDataUnderReview();
		// },   

		// updatePageApproved(data){
		// 	this.currentPageApproved = data;
		// 	this.getDataApproved();
		// },   

		// updatePageDeclined(data){
		// 	this.currentPageDeclined = data;
		// 	this.getDataDeclined();
		// },   

		// updatePageDispatched(data){
		// 	this.currentPageDispatched = data;
		// 	this.getDataDispatched();
		// },   

		// updatePageDelivered(data){
		// 	this.currentPageDelivered = data;
		// 	this.getDataDelivered();
		// },   

		// updatePageCompleted(data){
		// 	this.currentPageCompleted = data;
		// 	this.getDataCompleted();
		// },   

		// updatePageCancelled(data){
		// 	this.currentPageCancelled = data;
		// 	this.getDataCancelled();
		// },   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 
		openhistoryModal(item){
	    	this.historyModal = true;
	    	this.selectedRow = item;
	    },
 		 opentransferModal(item){
	    	this.transferModal = true;
	    	this.selectedRow = item;
	    },
	    getData(){  

	this.isLoading=true;
	    	var current_location_id = this.filter.current_location_id;
	    	if(current_location_id == "all" || current_location_id == null){
	    		current_location_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	} 
	    	var classification = this.filter.classification;
	    	if(classification == "all" || classification == null){
	    		classification = ""
	    	} 
	    	var status = this.filter.status;
	    	if(status == "all" || status == null){
	    		status = ""
	    	} 
	    	axios.get(config.api_path+'/equipment?description='+this.filter.description+'&classification='+classification+'&current_location_id='+current_location_id+'&origin_id='+origin_id+'&status='+status+'&asset_no='+this.filter.asset_no+'&type='+this.filter.type+'&model='+this.filter.model+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 

					value.model = value.model ?? '';
	    			value.asset_no = value.asset_no && value.asset_no != "null" ? value.asset_no : "N/A";
	    			value.transfer_date = value.transfer_date ? moment.unix(value.transfer_date).format('MM/DD/YYYY') : " ";
	    			value.acquisition_date = value.acquisition_date ? moment.unix(value.acquisition_date).format('MM/DD/YYYY') : " ";
 					
					value.equipment_prep_no = value.equipment_prep_no ?? '';
					value.depot_name = value?.depot?.setting_name ?? '-';
					value.description = value?.description ? this.$options.filters.truncate(value.description):'-';

 					if(value.origin_id){
 						value.origin_id_label = value.origin.customer_name
 					}
 					else{
 						value.origin_id_label = "N/A";
 					}

 					value.current_location_id_label = "N/A"
 					if(value.current_location_id){
 						value.current_location_id_label = value.current_location.customer_name
 					}

	    			return value;
	    		}); 
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	    updateModal(item){ 
	    	this.$router.push('/main/update_equipment/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/main/view_equipment/'+item.id) 
	    },

	 
	    // getVendor(){

	    // 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vendorList = response.data;
	    // 		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.vendor_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	    // getDepot(){

	    // 	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.depotList = response.data;
	    // 		this.depotList.data = this.depotList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.setting_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // }, 

	   

	    download(){
	    	var current_location_id = this.filter.current_location_id;
	    	if(current_location_id == "all" || current_location_id == null){
	    		current_location_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/equipment?description='+this.filter.description+'&classification='+this.filter.classification+'&current_location_id='+current_location_id+'&origin_id='+origin_id+'&status='+this.filter.status+'&asset_no='+this.filter.asset_no+'&type='+this.filter.type+'&model='+this.filter.model+'&page='+this.currentPage+'&limit=20') 
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)

				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 10000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
 	}
}
</script>
